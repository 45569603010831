import React, { useState } from "react";
import classNames from "classnames/bind";
import styles from "./Home.css";

import NavBar from "./sections/NavBar/NavBar";
import HeroSection from "./sections/HeroSection/HeroSection";
import WhatIsImpact from "./sections/WhatIsImpact/WhatIsImpact";
import Contact from "./sections/Contact/Contact";
import About from "./sections/About/About";
import Photos from "./sections/Photos/Photos";

import NavBarEN from "./sections-en/NavBar/NavBar";
import HeroSectionEN from "./sections-en/HeroSection/HeroSection";
import WhatIsImpactEN from "./sections-en/WhatIsImpact/WhatIsImpact";
import ContactEN from "./sections-en/Contact/Contact";
import AboutEN from "./sections-en/About/About";
import PhotosEN from "./sections-en/Photos/Photos";

const cx = classNames.bind(styles);

const Home = () => {
  const [lang, setLang] = useState(navigator.language === "nl" ? "nl" : "en");
  return lang === "nl" ? (
    <div className="Home">
      <NavBar toggleLang={() => setLang("en")} />
      <HeroSection />

      <Photos />
      <About />
      <WhatIsImpact />

      <Contact />
    </div>
  ) : (
    <div className="Home">
      <NavBarEN toggleLang={() => setLang("nl")} />
      <HeroSectionEN />

      <PhotosEN />
      <AboutEN />
      <WhatIsImpactEN />

      <ContactEN />
    </div>
  );
};

export default Home;
